<template>
  <v-dialog
      v-model="showOfferPopup"
      max-width="780"
      @input="closeOfferPopup"
      persistent
  >
    <v-card class="px-0 pt-0">
      <div class="px-4 pt-4 pb-0 d-flex justify-space-between align-center border-bottom">
        <div class="">
          <h3 class="font-semibold text-blue pb-2">
            Available Packages
          </h3>
        </div>
        <div class="pointer" @click="closeOfferPopup">
          <ModalCloseIcon/>
        </div>
      </div>
      <div class="offer-listing-div px-4 py-4">
        <div v-for="offer in offers" :key="offer.id" class="my-4">
          <PackageCard :offer="offer" :is-offer-popup="true" @applyOffer="applyOffer(offer)"/>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import PackageCard from "@/components/Shop/Offers/PackageCard.vue";
import ModalCloseIcon from "@/assets/images/misc/modal-close.svg";

export default {
  name: "PackageCardPopup",
  props: {
    showOfferPopup: {type: Boolean, default: false},
    offers: {type: Array, default: () => []}
  },
  components: {ModalCloseIcon, PackageCard},
  computed: {
    getCurrentVenueId() {
      return this.$store.getters.svid;
    },
  },
  methods: {
    applyOffer(offer) {
      if (!offer) {
        this.showError("Offer Invalid");
        return;
      }
      this.showLoader('wait');
      let products = [];
      offer.package_products.forEach(p => {
        let taxAmount = 0;
        let priceBeforeTax = p.offer_price;
        if(p.tax_type_id === 1){
          // Calculate the actual price (exclusive of VAT)
          priceBeforeTax = p.offer_price / (1 + 0.05);
          taxAmount = p.offer_price - priceBeforeTax;
        }
        let obj = {
          offer_package_id: p.offer_package_id,
          product_id: p.product_id,
          actual_total_price: p.price,
          offer_price: p.offer_price,
          venue_id: p.venue_id,
          tax_type_id: p.tax_type_id ? p.tax_type_id : 1,
          price: priceBeforeTax,
          tax: taxAmount,
          quantity: p.quantity,
          total_price: p.offer_price,
          offer_name: offer.package_name,
        }
        products.push(obj);
      });
      if(products.length > 0) {
        this.$store.dispatch("applyOfferToCartItem", products).then( () => {
           this.hideLoader();
           this.showSuccess("Offer applied");
           this.$emit("close");
        });
      }else{
        this.hideLoader();
        this.showError("Offer Invalid");
        return;
      }

    },
    closeOfferPopup(){
      const exProductIds = this.offers.flatMap(offer =>
          offer.package_products.map(product => product.product_id)
      );
      if(exProductIds && exProductIds.length > 0) {
        this.$store.dispatch("addOfferProductExcluded",exProductIds,false);
      }
      this.$emit('close');
    }
  },

}
</script>

<style lang="scss" scoped>
::v-deep .search {
  border-radius: 4px;
  border: 1px solid #EFEDED;
  background: #FFF;
  max-width: 250px;

  .v-label {
    opacity: 0.3;
  }
}
</style>