<template>
  <div class="cart-product">
    <p class="mb-0">
      {{ product.name }} dadad
    </p>
    <div>
      <v-btn class="mb-0" icon>
        <DeleteIcon/>
      </v-btn>
    </div>
<!--    <p class="mb-0">-->
<!--      Part of: {{ parentProduct.name }}-->
<!--    </p>-->
    <p class="mb-0 font-semibold">
      {{ product.total_price | toCurrency }}
    </p>
  </div>
</template>

<script>
import DeleteIcon from "@/assets/images/misc/delete-icon.svg";
import moment from "moment";

export default {
  computed: {
    moment() {
      return moment
    }
  },
  components: {DeleteIcon},
  props: {
    product: {
      type: Object,
      required: true
    },
    // parentProduct: {
    //   type: Object,
    //   required: false
    // }
  },
}
</script>

<style lang="scss" scoped>
.cart-product {
  border-radius: 0.75rem;
  border: 1px solid #E8E8E8;
  background: #FFF;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  align-items: center;

  :nth-child(2n) {
    text-align: right;
    align-self: end;
  }
}
</style>