<template>
  <div class="rounded-lg offer-div">
    <div class="pa-3 d-flex flex-column">
      <p class="font-semibold text-md line-clamp-1 mb-2 pl-1">{{ offer.package_name }}</p>
      <v-simple-table class="table p-4 table-bordered overflow-y-auto" style="background-color: #FFFFFF; border-radius: 12px;">
          <thead>
            <tr>
              <td class="v-title">Venue</td>
              <td class="v-title">Type</td>
              <td class="v-title">Product</td>
              <td class="v-title">Quantity</td>
              <td class="v-title">Price (Inc Vat)</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(product,tp) in offer.package_products" :key="tp">
              <td class="v-name">{{ product.venue_name?product.venue_name:'-' }}</td>
              <td class="v-name">{{ product.product_type }}</td>
              <td class="v-name ">{{ product.product_name }}</td>
              <td class="v-name">{{ product.quantity }}</td>
              <td class="v-name">{{ product.offer_price | toCurrency }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      <div class="d-flex justify-space-between mt-3 pl-1">
          <div class="price">
            <span class="blue-text font-semibold">Price:  <span class="text-decoration-line-through font-12 text-light-gray">{{ getOfferOrignalPrice | toCurrency }}</span> {{ offer.offer_price | toCurrency }}</span>
          </div>
          <div class="add-to-cart" v-if="isOfferPopup">
            <v-btn
                class="white--text teal-color"
                height="36"
                text
                @click="apply"
            >
              Apply Offer
            </v-btn>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PackageCard",
  props: {
    offer: { type: Object, required: true },
    isOfferPopup: { type: Boolean, default: false },
  },
  data() {
    return {
      eventTicketModal: false,
      date: null,
    }
  },
  computed:{
    getOfferOrignalPrice(){
      return this.offer.package_products.reduce( (p,c) => p + c.price,0);
    },
  },
  methods:{
    closeModal(){
      this.eventTicketModal = false;
      this.offer.tickets.forEach((ele) => {
        ele.quantity = 0;
      })
    },
    apply(){
      this.$emit("applyOffer");
    }
  }
}
</script>

<style scoped>
.offer-div{
  background: #F0F5F9;
  .v-title{
    font-weight: 600;
  }
  .table-bordered {
    td.v-title,td.v-name {
      border-left: none !important;
      border-right: none !important;
      border-top: none !important;
    }
  }
}
</style>