<template>
  <div class="item-box">
    <div :data-label="venue.name" class="cart-product custom-ribbon">
      <v-row>
        <v-col cols="4"></v-col>
        <v-col cols="4">
          <p class="mb-2 line-clamp-2 text-center font-semibold">
            {{ productType }}
          </p>
        </v-col>
        <v-col class="d-flex justify-end" cols="4">
          <v-btn class="mb-0 svg-stroke-red" height="20" icon @click="removeCartItem">
            <DeleteIcon/>
          </v-btn>
        </v-col>
      </v-row>
      <div class="d-flex justify-space-between product-row mb-2">
        <p class="mb-0">
          {{ productType }} Name:
        </p>
        <p class="mb-0 font-medium">
          {{ getProductTypeName(product) }}
        </p>
      </div>
      <div class="d-flex justify-space-between product-row mb-2">
        <p class="mb-0">
          Product:
        </p>
        <p class="mb-0 font-medium">
          {{ product.quantity + ' x ' + product.name }}
        </p>
      </div>
      <div class="d-flex justify-space-between product-row mb-2">
        <p class="mb-0">
          Price:
        </p>
        <p v-if="product.quantity < 1" class="mb-0 font-medium">
          <span v-if="product.discount">
            <span  class="discount-item">{{ product.discount.actual_total | toCurrency }} </span>
            <span class="item-amount">{{ product.total_price | toCurrency }}</span>
          </span>
          <span v-else-if="product.offer_package_id">
              <span  class="discount-item">{{ (product.total_price * product.quantity) | toCurrency }} </span>
              <span class="item-amount">{{ getOfferCalculatedPrice | toCurrency }}</span>

          </span>
          <span v-else class="item-amount">{{ product.total_price | toCurrency }}</span>
        </p>
        <p v-else class="mb-0 font-medium">
          <span v-if="product.discount">
             <span  class="discount-item">{{ product.discount.actual_total | toCurrency }} </span>
             <span class="item-amount">{{ (product.total_price * product.quantity) | toCurrency }}</span>
          </span>
          <span v-else-if="product.offer_package_id">
              <span  class="discount-item">{{ (product.total_price * product.quantity) | toCurrency }} </span>
              <span class="item-amount">{{ getOfferCalculatedPrice | toCurrency }}</span>
          </span>
          <span class="item-amount" v-else>{{ (product.total_price * product.quantity) | toCurrency }}</span>
        </p>
      </div>
      <div v-if="shouldExpandBeVisible" class="d-flex justify-center show-more">
        <v-btn :class="{'rotate-180':showMore}" :ripple="false" class="bg-transparent" depressed
               elevation="0" height="20" max-width="21" @click="showMore = !showMore">
          <ArrowDownIcon/>
        </v-btn>
      </div>
      <template v-if="showMore">
        <div v-if="item.start_time" class="d-flex justify-space-between product-row mb-2">
          <p class="mb-0">
            Arrival Time:
          </p>
          <p class="mb-0 font-medium">
            {{ moment(item.start_time, "HH:mm:ss").format("hh:mm A") }}
            <span v-if="item.repeat" title="Long Term Booking"><v-icon> mdi-repeat-variant </v-icon></span>
          </p>
        </div>
        <div v-if="item.start_date" class="d-flex justify-space-between product-row mb-2">
          <p class="mb-0">
            {{ productType }} Date:
          </p>
          <p class="mb-0 font-medium">
            {{ moment(item.start_date).format("DD MMM, YYYY") }}
          </p>
        </div>
        <div v-if="item.surface" class="d-flex justify-space-between product-row mb-2">
          <p class="mb-0">
            Surface:
          </p>
          <p class="mb-0 font-medium">
            {{ product.surface }}
          </p>
        </div>
        <div class="d-flex justify-space-between product-row mb-2" v-if="product.offer_package_id">
          <p class="mb-0">
            Offer applied: {{ product.offer?product.offer.offer_name:'-' }}
          </p>
        </div>
      </template>

    </div>
  </div>
</template>

<script>
import DeleteIcon from "@/assets/images/misc/delete-icon.svg";
import ArrowDownIcon from "@/assets/images/shop/arrow-down.svg";
import moment from "moment";

export default {
  computed: {
    moment() {
      return moment
    },
    shouldExpandBeVisible() {
      return this.item.start_time || this.item.start_date || this.product.surface || this.product.offer;
    },
    productType() {
      switch (this.product.product_type_id) {
        case 2:
          return "Membership";
        case 3:
          return "Event";
        case 4:
          return "Academy";
        case 5:
          return "Trainer";
        case 6:
          return "Facility";
        case 9:
          return "Retail";
        default:
          return "";
      }
    },
    venue() {
      return this.$store.getters.getShopAvailableVenues.find(venue => venue.id == this.item.venueId) || {};
    },
    getOfferCalculatedPrice(){
      if(this.product.offer_package_id){
        if(this.product.quantity >= 1){
            let totalProductPrice = this.product.total_price * (this.product.quantity -  this.product.offer.quantity);
            return totalProductPrice + this.product.offer.total_price;
        }
        return this.product.total_price;
      }else{
        return 0;
      }
    }
  },
  components: {DeleteIcon, ArrowDownIcon},
  props: {
    product: {
        type: Object,
        required: true,
        default: () => ({
          offer: {} // Initialize offer as an object to ensure reactivity
        })
    },
    item: {
      type: Object, default: () => {
      }
    }
  },

  data() {
    return {
      showMore: false
    }
  },
  methods: {
    getProductTypeName(product = null) {
      switch (product.product_type_id) {
        case 2:
          return this.item.membership_name;
        case 3:
          return this.item.event_name;
        case 4:
          return this.item.program_name;
        case 5:
          return this.item.trainer_name;
        case 6:
          return this.item.facility_name;
        case 9:
          return this.product.category;
      }
    },
    removeCartItem() {
      this.$emit("removeCartItem");
    }
  }
}
</script>

<style lang="scss" scoped>
.item-box {
  position: relative;
  font-size: 14px;
  min-height: fit-content;

  * {
    transition: all 0.3s;
  }
}

.cart-product {
  border-radius: 0.75rem;
  border: 1px solid #E8E8E8;
  background: #FFF;
  padding: 1rem;
}

.custom-ribbon {
  &::before {
    position: absolute;
    top: 2px;
    left: -4px;
    content: "";
    background: #00b0af;
    height: 12px;
    width: 12px;
    transform: rotate(136deg);
  }

  &::after {
    position: absolute;
    content: attr(data-label);
    top: -8px;
    left: -7px;
    padding: 2px 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background: #00b0af;
    font-size: 10px;
    color: white;
    text-align: center;
  }
}

span.discount-item {
  text-decoration: line-through;
  font-size: 12px;
  color: #ff0606;
}

.show-more {
  position: absolute;
  bottom: -10px;
  left: 42.5%;
}

.v-btn::before {
  background-color: transparent !important;
}

.rotate-180 {
  transform: rotate(180deg);
}

.product-row {
  p:nth-child(2) {
    width: 60%;
    text-align: right;
  }
}
</style>